<a
  class="content-title-item content-title-item--{{ color }}"
  [routerLink]="['/', 'content', 'product', content.slug]"
  [routerLinkActive]="'content-title-item--is-active'">

  @if (content.coverImage?.data) {
    <img
      loading="lazy"
      class="content-title-item__image"
      [src]="content.coverImage?.data?.attributes?.formats?.small?.url"
      [alt]="'Featured content title cover image for ' + content.title"
      [width]="content.coverImage?.data?.attributes?.formats?.small?.width"
      [height]="content.coverImage?.data?.attributes?.formats?.small?.height">
  } @else {
    <img
      loading="lazy"
      class="content-title-item__image"
      src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/product_placeholder_f8b0a74052.webp"
      alt="Product cover placeholder"
      width="152"
      height="222">
  }

  <div class="content-title-item__info">
    <p class="content-title-item__title">
      {{ content.title }}
    </p>

    <p class="content-title-item__desc">
      {{ getContentDescription() }}
    </p>
  </div>
</a>
