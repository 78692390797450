import { Component, Input, inject } from '@angular/core';
import { IContent } from 'src/app/content/content.interface';
import { HelperFunctionsService } from 'src/app/core/helper-functions/helper-functions.service';
import { ESectionColor } from '../sections/section.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-content-title-item',
  templateUrl: './content-title-item.component.html',
  styleUrl: './content-title-item.component.scss',
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class ContentTitleItemComponent {
  @Input({ required: true }) public content!: IContent;
  @Input({ required: true }) public color!: ESectionColor;

  private readonly _helperFunctions: HelperFunctionsService = inject(HelperFunctionsService);

  public getContentDescription(): string {
    return this._helperFunctions.truncateText(this.content.description, 10);
  }
}
